html {
  font-size: 62.5%;
}


body {
  margin: 0;
  font-family: 300 11px/1.4 'Helvetica Neue','sans-serif';
  color: #444;
  /* background color */
  /* background: #022c43;  */
  background: #171717; 
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




@media screen and (max-width: 1300px) {
  body {
    overflow: visible;
  }
}