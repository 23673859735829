@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.text-sphere {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: right;
    right: 5%;
    align-items: center;
    
    
}

.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 1.3em;
}

.tagcloud--item{
    color: #fff;
    text-transform: uppercase;
}

.tagcloud--item:hover{
    color: red;
}